import React, { Component, lazy, Suspense } from "react";
import { IonApp, IonSpinner, setupIonicReact, isPlatform } from "@ionic/react";

import "./SharedCode/theme/variables.css";
import "./App.css";
import ErrorBoundary from "./SharedCode/components/ErrorBoundary";

if (
  (isPlatform("ios") || isPlatform("android")) &&
  isPlatform("mobileweb") === false &&
  isPlatform("tablet") === false
) {
  setupIonicReact({
    swipeBackEnabled: true,
  });
} else {
  setupIonicReact({
    swipeBackEnabled: false,
  });
}

const SharedApp = lazy(() => import("./SharedCode/SharedApp"));

type Props = {};
type State = {
  showLoadingScreen: boolean;
};

class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showLoadingScreen: true,
    };
  }

  componentDidMount() {}

  showLoadingScreen(shouldShow: boolean) {
    this.setState({ showLoadingScreen: shouldShow });
  }

  render() {
    const app = "Plus";

    return (
      <IonApp>
        <Suspense
          fallback={
            <div className="loadingScreen">
              <div className="loadingScreenText">Loading</div>
              <IonSpinner className="loadingScreenSpinner" />
            </div>
          }
        >
          <>
            <ErrorBoundary>
              <SharedApp app={app} showLoadingScreen={this.showLoadingScreen.bind(this)} />
            </ErrorBoundary>
            {this.state.showLoadingScreen ? (
              <div className="loadingScreen">
                <div className="loadingScreenText">Loading</div>
                <IonSpinner className="loadingScreenSpinner" />
              </div>
            ) : null}
          </>
        </Suspense>
      </IonApp>
    );
  }
}

export default App;
